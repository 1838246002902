import { createTheme } from "@material-ui/core/styles";

const miBlue = '#52b4db';
const miBlueDark = '#33648a';

const MiTheme = createTheme({
    palette: {
        primary: {
            main: miBlueDark,
            light: miBlue
        },
        secondary: {
            main: '#ffb74d',
        }
    },
    typography: {
        h3: {
            color: miBlueDark,
            fontWeight: 'bold',
        },
        h4: {
            color: miBlueDark,
            fontWeight: 'bold',
        },
        h5: {
            color: miBlueDark,
            fontWeight: 'bold',
        },
        h6: {
            color: miBlueDark,
            fontWeight: 'bold',
        }
    },
    props: {
        MuiTextField: {
            size: 'small',
            margin: 'dense',
        }
    },
    overrides: {
        MuiButton: {
            containedSecondary: {
                color: '#fff',
            },
        },
        MuiFab: {
            secondary: {
                color: '#fff',
            }
        }
    },

});

export default MiTheme;
