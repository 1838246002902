import React, {useEffect, useState} from 'react';
import axios from 'axios';
import logo from './MedExpert_Globe_Blue.png';
import Paper from "@material-ui/core/Paper";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import MiTheme from "./MiTheme";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    employeesContainer: {
        padding: "5% 20% 5% 20%",
        [theme.breakpoints.down('sm')]: {
            padding: "5% 0% 5% 0%",
        },
    },
    padding2: {
        padding: theme.spacing(4)
    },
    employeesPaper: {
        // padding: "5% 15% 5% 15%",
        // [theme.breakpoints.down('sm')]: {
        //   padding: "5% 5% 5% 5%",
        // },
    },
    gridMargin: {
        padding: '5% 5% 5% 5%',
    },
    textMargin: {
        marginTop: '20px',
    },
}));

export default () => {
    const classes = useStyles();
    const [data, setData] = useState('');

    const verifyEmail = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('token');

        if(!token) {
            setData({html: `<h4 class=\'blue_header\'>Error</h4><p>Empty request.</p>`});
            return;
        }
        axios.get(`/verify_email?token=${encodeURIComponent(token)}`)
            .then((response) => {
                    console.log(response.data);
                    const responseData = response.data;
                    setData(responseData);
                }
            )
            .catch(error => setData({html: `<h4 class=\'blue_header\'>Error</h4><p>${error}</p>`}));
    }

    useEffect(() => {
        console.log("use effect");
        verifyEmail();
    }, []);

    const renderComponent = () => {
        return ( data &&
            <ThemeProvider theme={MiTheme}>
                <Grid container spacing={1} className={classes.gridMargin}>
                    <Grid item xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: data['html'] }}/>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    };

    return (
        <div className={classes.employeesContainer}>
            <Paper elevation={3} className={classes.employeesPaper}>
                <Grid container spacing={2} alignItems='center'>
                    <Hidden xsDown>
                        <Grid item sm={4} className={classes.padding2}>
                            <div className={classes.padding2}>
                                <img src={logo} alt="Logo"/>
                            </div>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8}>
                        {renderComponent()}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
};
